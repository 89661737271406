<template>
  <div ref="sliderContainer" class="os-slider">
    <Slider
      v-model="sliderModel"
      :class="{ 'mt-8': tooltips }"
      :min="min"
      :max="max"
      :tooltips="tooltips"
      :format="format"
      :disabled="disabled"
      :lazy="false"
      v-bind="$attrs"
      :aria-labelledby="'slider handle'"
      data-testid="slider"
      :aria="{
        'aria-label': 'range slider handle'
      }"
      @slide="updateTooltipCss"
      @start="tooltipWatingTime = 0"
      @end="tooltipWatingTime = 300"
      @change="onChange"
    />
    <div v-if="showMarks" class="os-slider__marks">
      <span class="os-slider__marks__values">{{ minValueLabel }} </span>
      <span class="os-slider__marks__values"> {{ maxValueLabel }}</span>
    </div>
  </div>
</template>

<script lang="ts">
// eslint-disable-next-line import/default
import Slider from '@vueform/slider'
import { IGoogleDataLayerOptions } from '~/types/googleDataLayer'

export default {
  name: 'InputSlider',

  components: {
    Slider
  },

  props: {
    modelValue: {
      type: [Number, Array] as PropType<number | number[]>,
      required: false,
      default: undefined
    },

    min: {
      type: Number,
      required: false,
      default: 0
    },

    max: {
      type: Number,
      required: false,
      default: 100
    },

    tooltips: {
      type: Boolean,
      required: false,
      default: true
    },

    prefix: {
      type: [String, Object] as PropType<
        { one: string; many: string } | string
      >,
      required: false,
      default: ''
    },

    suffix: {
      type: [String, Object] as PropType<
        { one: string; many: string } | string
      >,
      required: false,
      default: ''
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false
    },

    tooltipFormatter: {
      type: Function,
      required: false,
      default: undefined
    },

    markerFormatter: {
      type: Function,
      required: false,
      default: undefined
    },

    isNumberFormat: {
      type: Boolean,
      required: false,
      default: true
    },

    showMarks: {
      type: Boolean,
      default: true,
      required: false
    },
    trackingOptions: {
      type: Object as PropType<Partial<IGoogleDataLayerOptions>>,
      default: null,
      required: false
    }
  },

  emits: ['update:modelValue', 'change'],

  setup(props, { emit }) {
    const { $dataLayer } = useNuxtApp()
    const sliderModel = computed<number | number[]>({
      get() {
        return props.modelValue || props.max
      },
      set(val) {
        emit('update:modelValue', val)
      }
    })

    const previousValue = ref<number | number[]>(sliderModel.value)
    const minValueLabel = computed<string>(() =>
      props.markerFormatter
        ? props.markerFormatter(props.min)
        : formatter(props.min)
    )
    const maxValueLabel = computed<string>(() =>
      props.markerFormatter
        ? props.markerFormatter(props.max)
        : formatter(props.max)
    )

    const formatter = (value: number): string => {
      const localeValue = props.isNumberFormat ? value.toLocaleString() : value

      const suffix =
        typeof props.suffix === 'string'
          ? props.suffix
          : (value === 1 ? props.suffix.one : props.suffix.many) || ''

      const prefix =
        typeof props.prefix === 'string'
          ? props.prefix
          : (value === 1 ? props.prefix.one : props.prefix.many) || ''

      return `${prefix} ${localeValue} ${suffix}`.trim()
    }

    // tooltip formatting
    const format = (value: number): string => {
      if (props.tooltipFormatter) {
        return props.tooltipFormatter(value)
      }

      return formatter(value)
    }

    // tooltip css
    const tooltipWatingTime = ref(300)
    const sliderContainer = ref()

    const updateTooltipCss = !props.tooltips
      ? () => {}
      : async () => {
          // when the slider bar is clicked instead of dragged we should wait 300ms, which is the transition time
          await new Promise((resolve) =>
            setTimeout(resolve, tooltipWatingTime.value)
          )

          if (!sliderContainer.value) return

          const firstLabel = sliderContainer.value.querySelector(
            '.slider-tooltip-top'
          )

          updateTooltipPosition(firstLabel)

          if (!Array.isArray(sliderModel.value)) return

          const secondLabel = sliderContainer.value.querySelectorAll(
            '.slider-tooltip-top'
          )[1]

          updateTooltipPosition(secondLabel)
        }

    const updateTooltipPosition = (labelElement: HTMLElement) => {
      if (!labelElement) return

      const baseTranslate = labelElement.parentElement?.className.includes(
        'slider-origin'
      )
        ? '50%'
        : '-50%'

      labelElement.style.transform = ``

      const sliderStart = sliderContainer.value.getBoundingClientRect().x
      const sliderEnd =
        sliderStart + sliderContainer.value.getBoundingClientRect().width

      const labelElementPosition =
        labelElement?.getBoundingClientRect() ||
        sliderContainer.value.getBoundingClientRect()

      if (labelElement && labelElementPosition.x < sliderStart) {
        labelElement.style.transform = `translate(calc(${baseTranslate} + ${
          sliderStart + -labelElementPosition.x
        }px))`

        return
      }

      const labelElementEnd =
        labelElementPosition.x + labelElementPosition.width
      if (labelElement && labelElementEnd > sliderEnd) {
        labelElement.style.transform = `translate(calc(${baseTranslate} - ${
          labelElementEnd - sliderEnd
        }px))`

        return
      }

      labelElement.style.transform = ``
    }

    onMounted(updateTooltipCss)

    const onChange = () => {
      if (props.trackingOptions) {
        // Tracking for Range Slider:[min, max]
        if (
          Array.isArray(previousValue.value) &&
          Array.isArray(sliderModel.value)
        ) {
          const action =
            previousValue.value[0] !== sliderModel.value[0]
              ? 'min-manual'
              : 'max-manual'

          $dataLayer.linkClick({
            action,
            ...props.trackingOptions
          })
        } else {
          $dataLayer.linkClick({
            ...props.trackingOptions
          })
        }
      }
      previousValue.value = sliderModel.value
      emit('change')
    }

    return {
      sliderContainer,
      tooltipWatingTime,
      updateTooltipCss,
      sliderModel,
      minValueLabel,
      maxValueLabel,
      format,
      onChange,
      previousValue
    }
  }
}
</script>

<!-- <style src="@vueform/slider/themes/default.css"></style> -->

<style lang="scss">
@import url('@vueform/slider/themes/default.css');

.os-slider {
  // TODO: Find away to use variables??
  --slider-bg: #d7d7d7;
  --slider-height: 4px;
  --slider-connect-bg: #0078d6;

  --slider-handle-bg: #0078d6;
  --slider-handle-width: 24px;
  --slider-handle-height: 24px;
  --slider-handle-ring-width: 0;

  --slider-tooltip-font-size: rem(18);
  --slider-tooltip-bg: transparent;
  --slider-tooltip-bg-disabled: transparent;
  --slider-tooltip-color: #333333;
  --slider-tooltip-py: 0;
  --slider-tooltip-px: 0;
  --slider-tooltip-radius: 0;

  --slider-tooltip-bg-min: var(--slider-tooltip-bg);
  --slider-tooltip-bg-max: var(--slider-tooltip-bg);
  --slider-handle-bg-min: var(--slider-handle-bg);
  --slider-handle-bg-max: var(--slider-handle-bg);

  max-width: 100%;
  padding-top: rem(12);

  .slider-origin {
    .slider-handle {
      background: var(--slider-handle-bg-min);
    }
    .slider-tooltip {
      background: var(--slider-tooltip-bg-min);
      border-color: var(--slider-tooltip-bg-min);
    }
  }

  .slider-origin:last-child {
    .slider-handle {
      background: var(--slider-handle-bg-max);
    }

    .slider-tooltip {
      background: var(--slider-tooltip-bg-max);
      border-color: var(--slider-tooltip-bg-max);
    }
  }

  .slider-target {
    padding: 0 rem(12);
  }

  .slider-tooltip {
    font-family: $mb-font-text;
  }

  .os-slider__label {
    position: absolute;
    top: -32px;
    width: 100%;
    pointer-events: none;
  }

  .os-slider__label__value {
    position: absolute;
    font-size: rem(18);
    color: $black--light;
    letter-spacing: -0.2px;
    text-align: center;
    line-height: 14px;
    width: max-content;
  }
}

.os-slider__marks {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: rem(10) rem(12) rem(16);
}

.os-slider__marks__values {
  font-family: $mb-font-text;
  font-size: rem(18);
  letter-spacing: 0.2px;
  width: 40%;
  color: $grey--darkest;

  &:first-child {
    margin-left: rem(-12);
    text-align: left;
  }

  &:last-child {
    margin-right: rem(-12);
    text-align: right;
  }
}
</style>
